import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';
import Stripe from '../Payments/Stripe';
import PaymentMethodField from '../Components/PaymentMethodField';
import SubscriptionAccount from './SubscriptionAccount';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, error: '', message: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('subscriptions', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          const subscriptions = response.data;

          const activeSubscription = subscriptions.filter((s) => s.status === 'active')[0];
          const incompleteSubscription = subscriptions.filter((s) => s.status === 'incomplete')[0];
          const failedSubscription = subscriptions.filter((s) => s.status === 'past_due')[0];
          const trialSubscription = subscriptions.filter((s) => s.status === 'trialing')[0];
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: (
              activeSubscription
              || incompleteSubscription
              || failedSubscription
              || trialSubscription
            ),
          });
        } else if (response.status_code === 404) {
          dispatch({ type: 'FETCH_SUCCESS', message: 'You have no active subscription' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.error });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  const onCancelSubscription = () => {
    // eslint-disable-next-line no-alert
    const result = window.confirm('Do you want to deactivate the subscription?');

    if (result) {
      dispatch({ type: 'FETCH_INIT' });
      sendRequest('subscriptions', 'PATCH', { cancel_at_period_end: true })
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({ type: 'FETCH_SUCCESS', payload: response.data, message: response.message });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.error });
          }
        });
    }
  };

  const onReactivateSubscription = () => {
    // eslint-disable-next-line no-alert
    const result = window.confirm('Do you want to reactivate the subscription?');

    if (result) {
      dispatch({ type: 'FETCH_INIT' });
      sendRequest('subscriptions', 'PATCH', { cancel_at_period_end: false })
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({ type: 'FETCH_SUCCESS', payload: response.data, message: response.message });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.error });
          }
        });
    }
  };

  if (!state.data) {
    return (
      <Card className="mb-4 flex-fill">
        <Card.Body>
          <Card.Title>Subscription Info</Card.Title>
          <Alert variant="warning">
            {state.message || 'You have no active subscription'}
          </Alert>

          <LinkContainer to="/plans">
            <Button variant="primary" className="mb-3 mb-md-0">Purchase</Button>
          </LinkContainer>

          <LinkContainer to="/plans?tab=trial">
            <Button variant="outline-primary" className="ml-md-3">Start Free Trial</Button>
          </LinkContainer>
        </Card.Body>
      </Card>
    );
  }

  if (state.data.status === 'incomplete') {
    return (
      <Elements stripe={stripePromise}>
        <IncompleteSubscription subscription={state.data} />
      </Elements>
    );
  }

  if (state.data.status === 'past_due') {
    return (
      <Elements stripe={stripePromise}>
        <FailedSubscription subscription={state.data} />
      </Elements>
    );
  }

  return (
    <>
      {
        state.isLoading
          ? <Loader />
          : (
            <Card className="mb-4 flex-fill">
              <Card.Body>
                <Card.Title>
                  Active subscription:&nbsp;
                  <strong>{state.data.plan.name}</strong>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {
                    state.data.status === 'active' && (
                      <>
                        {state.data.plan.price}
                        € per
                        {' '}
                        {state.data.plan.period}
                      </>
                    )
                  }
                </Card.Subtitle>

                {
                  state.message && (
                    <Alert variant="warning">
                      {state.message}
                    </Alert>
                  )
                }

                <ul className="list-group mt-4 mb-4">
                  <li className="list-group-item">{state.data.plan.description || 'No description'}</li>
                  <li className="list-group-item">
                    {state.data.plan.prints_included}
                    &nbsp;
                    documents
                  </li>
                  {
                    state.data.plan.price !== 0 && (
                    <li className="list-group-item">
                      {state.data.plan.price}
                      € per
                      {' '}
                      {state.data.plan.period}
                    </li>
                    )
                  }
                  {
                    state.data.plan.price !== 0 && (
                    <li className="list-group-item">
                      {state.data.plan.price_per_print}
                      € per print
                    </li>
                    )
                  }
                </ul>

                {
                  state.data.status === 'trialing' && (
                    <Alert variant="warning">
                      <Alert.Heading className="h5">Your subscription is in trial mode</Alert.Heading>
                      <p>
                        You can use the Direct Print service to print documents.
                        Your trial will end at&nbsp;
                        <strong>
                          {
                            moment
                              .utc(state.data.date_current_period_end)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')
                          }
                        </strong>
                      </p>
                      <p>
                        Click button below to purchase a subscription
                      </p>
                      <LinkContainer to="/plans">
                        <Button variant="warning" className="mb-1">Upgrade Subscription</Button>
                      </LinkContainer>
                    </Alert>
                  )
                }

                {
                  // If no payment method set, ask to contact support to manager subscription
                  !state.data.payment_method_id && state.data.status !== 'trialing' && (
                    <Alert variant="warning">
                      <p className="mb-0">
                        Looks like you purchased the subscription using direct payment link.
                        Please contact support to manage your subscription.
                      </p>
                    </Alert>
                  )
                }

                {
                  // Subscription set to be canceled
                  state.data.payment_method_id && state.data.cancel_at_period_end === true && (
                    <Alert variant="warning">
                      <Alert.Heading className="h5">
                        Subscription will be canceled at
                        &nbsp;
                        {
                          moment
                            .utc(state.data.date_current_period_end)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')
                        }
                      </Alert.Heading>
                      <p className="mb-0">
                        You still can use the Direct Print service to print documents.
                        A final invoice will be issued at the end of the current period
                      </p>
                      <p>
                        Click &quot;Reactivate Subscription&quot; button to restore
                        your subscription
                      </p>
                      <Button variant="warning" className="mb-1" onClick={onReactivateSubscription}>Reactivate Subscription</Button>
                    </Alert>
                  )
                }

                {
                  state.data.payment_method_id && !state.data.cancel_at_period_end && (
                  <div className="mb-4">
                    <LinkContainer to="/plans" className="mr-3 mb-3">
                      <Button variant="warning">Upgrade Subscription</Button>
                    </LinkContainer>
                    <Button variant="link" className="mb-3 text-danger" onClick={onCancelSubscription}>Cancel Subscription</Button>
                  </div>
                  )
                }

                <SubscriptionAccount account={state.data.printnode_account} />
              </Card.Body>
            </Card>
          )
      }
    </>
  );
};

const FailedSubscription = ({ subscription }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, error: '', message: '', isLoading: false, isError: false,
    },
  );
  const [paymentMethodId, setPaymentMethodId] = React.useState(
    subscription.payment_method?.stripe_id,
  );
  const stripe = useStripe();

  const onRetryPayment = () => {
    dispatch({ type: 'FETCH_INIT' });

    const notPaidInvoice = subscription.invoices.filter((i) => i.date_paid === null)[0];

    const connector = new Stripe({
      stripe,
      onError: (error) => {
        if (typeof error === 'object') {
          dispatch({ type: 'FETCH_FAILURE', error: error.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error });
        }
      },
      onSuccess: () => {
        dispatch({
          type: 'FETCH_SUCCESS',
          message: 'Your subscription has been successfully paid. Please reload page to see updates.',
        });
      },
    });

    // paymentMethodId, invoiceId, planId
    connector.retryInvoiceWithNewPaymentMethod(
      paymentMethodId,
      notPaidInvoice.id,
      subscription.plan.stripe_id,
    );
  };

  const onReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <Card.Title>Subscription Info</Card.Title>

        {
        state.isLoading
          ? <Loader />
          : (
            <>
              <Alert variant="danger">
                <Alert.Heading className="h5">We can&apos;t charge the card from your subscription</Alert.Heading>
                {
                  state.error
                  || (
                    <>
                      <p>
                        Your subscription is past due. Pay to continue using our service.
                      </p>
                      <p>
                        You can use&nbsp;
                        <b>Retry Payment</b>
                        &nbsp;button to try to charge the current card or change card with&nbsp;
                        <b>Change Payment Method</b>
                        &nbsp;button.
                      </p>
                    </>
                  )
                }
              </Alert>

              <Form id="change-payment-method">
                <Form.Group>
                  <PaymentMethodField
                    label="Select payment method"
                    name="payment_method"
                    defaultPaymentMethod={paymentMethodId}
                    onChange={(newPaymentMethodId) => setPaymentMethodId(newPaymentMethodId)}
                  />
                </Form.Group>
                <Button variant="warning" onClick={onRetryPayment}>Retry Payment</Button>
              </Form>
            </>
          )
        }

        {
          state.message
          && (
            <>
              <Alert variant="success" className="mt-3">
                {state.message || 'Your subscription has been successfully paid. Please reload page to see updates.'}
                <Button variant="warning" size="sm" className="ml-md-3 ml-0" onClick={onReload}>Reload</Button>
              </Alert>
            </>
          )
        }

      </Card.Body>
    </Card>
  );
};

FailedSubscription.propTypes = {
  subscription: PropTypes.shape({
    invoices: PropTypes.arrayOf(PropTypes.shape({
      past_due: PropTypes.instanceOf(Date),
    })).isRequired,
    payment_method: PropTypes.shape({
      stripe_id: PropTypes.string.isRequired,
    }),
    plan: PropTypes.shape({
      stripe_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const IncompleteSubscription = ({ subscription }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, error: '', message: '', isLoading: false, isError: false,
    },
  );
  const [paymentMethodId, setPaymentMethodId] = React.useState(
    subscription.payment_method?.stripe_id,
  );
  const stripe = useStripe();

  const onRetryPayment = () => {
    dispatch({ type: 'FETCH_INIT' });

    const notPaidInvoice = subscription.invoices.filter((i) => i.date_paid === null)[0];

    const connector = new Stripe({
      stripe,
      onError: (error) => {
        if (typeof error === 'object') {
          dispatch({ type: 'FETCH_FAILURE', error: error.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error });
        }
      },
      onSuccess: () => {
        dispatch({
          type: 'FETCH_SUCCESS',
          message: 'Your subscription has been successfully paid. Please reload page to see updates.',
        });
      },
    });

    // paymentMethodId, invoiceId, planId
    connector.retryInvoiceWithNewPaymentMethod(
      paymentMethodId,
      notPaidInvoice.id,
      subscription.plan.stripe_id,
    );
  };

  const onReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <Card.Title>Subscription Info</Card.Title>

        {
        state.isLoading
          ? <Loader />
          : (
            <>
              <Alert variant="danger">
                <Alert.Heading className="h5">
                  Looks like you didn&apos;t finish your subscription
                </Alert.Heading>
                {
                  state.error
                  || (
                    <>
                      <p>
                        Your subscription is incomplete. Pay to continue using our service.
                      </p>
                      <p>
                        You can use&nbsp;
                        <b>Retry Payment</b>
                        &nbsp;button to try to charge the current card or change card with&nbsp;
                        <b>Change Payment Method</b>
                        &nbsp;button.
                      </p>
                    </>
                  )
                }
              </Alert>

              <Form id="change-payment-method">
                <Form.Group>
                  <PaymentMethodField
                    label="Select payment method"
                    name="payment_method"
                    defaultPaymentMethod={paymentMethodId}
                    onChange={(newPaymentMethodId) => setPaymentMethodId(newPaymentMethodId)}
                  />
                </Form.Group>
                <Button variant="warning" onClick={onRetryPayment}>Retry Payment</Button>
              </Form>
            </>
          )
        }

        {
          state.message
          && (
            <>
              <Alert variant="success" className="mt-3">
                {state.message || 'Your subscription has been successfully paid. Please reload page to see updates.'}
                <Button variant="warning" size="sm" className="ml-md-3 ml-0" onClick={onReload}>Reload</Button>
              </Alert>
            </>
          )
        }

      </Card.Body>
    </Card>
  );
};

IncompleteSubscription.propTypes = {
  subscription: PropTypes.shape({
    invoices: PropTypes.arrayOf(PropTypes.shape({
      past_due: PropTypes.instanceOf(Date),
    })).isRequired,
    payment_method: PropTypes.shape({
      stripe_id: PropTypes.string.isRequired,
    }).isRequired,
    plan: PropTypes.shape({
      stripe_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Subscription;
